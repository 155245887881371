<template>
    <div class="box-content">
      <router-view></router-view>
    </div>
  </template>
<script>
export default {
  name: 'ElVabMain'
}
</script>

<style scoped lang="less">
</style>
