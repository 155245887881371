<template>
  <div class="home-content">
    <el-container>
      <el-header>
        <Header />
      </el-header>
      <el-main>
        <Main />
      </el-main>
      <Footer />
    </el-container>
  </div>
</template>
<script>
import Header from './components/Header.vue'
import Main from './components/Main.vue'
import Footer from './components/Footer.vue'
export default {
  name: 'Layout',
  components: {
    Header,
    Main,
    Footer
  },
  data () {
    return {
    }
  }
}
</script>
<style lang="less" scoped>
.home-content {
  width: 100%;
  background-image: url('../assets/images/body.jpg');
    // background-position: center;
    background-repeat: no-repeat;
    // background-attachment: fixed;
    background-size: 100% 80%;
}
</style>
