<template>
  <div class="footer-box" id="footerb">
      <div class="footer-title">
        立即下载，开启高效工作新时代！
      </div>
      <div class="footer-content">
        <div class="footer-img">
            <img src="../../assets/images/code.jpg" alt="">
        </div>
        <div class="footer-code">扫码下载</div>
      </div>
      <div class="b-num">
        <span class="left-text">京ICP备2024060545号 </span>
        <span>   Beijing xiangliang Technology Co., Ltd </span>
      </div>
    </div>
</template>

<script>
export default {
}
</script>
<style lang='less' scoped>
.footer-box {
  width: 100%;
    padding: 5.8rem 0 5rem 0;
  background: radial-gradient(circle, #92d7e4, #e2f7e7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  .b-num {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    color: hsla(0, 10%, 22%, 0.582);
    font-size: 0.4375rem;
    .left-text {
      margin-right: 0.5rem;
    }
  }
  .footer-title {
    font-size: 1.5rem;
    color: #2A0E52;
    margin-bottom: 2.9rem;
    font-weight: bold;
  }
  .footer-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .footer-img{
        width: 8.5625rem;
        height: 8.5625rem;
        padding: 1.125rem;
        box-sizing: border-box;
        background: #fff;
        border-radius: 0.625rem;
        img {
            max-width: 100%;
            height: auto;
            object-fit: fill;
        }
    }
    .footer-code {
        font-size: 0.75rem;
        margin-top: 1.1rem;
        color: #626262;
    }
  }
}
</style>
