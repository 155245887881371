<template>
    <!-- 头部区域 -->
    <div class="header">
      <div class="container flexAlign">
        <!-- logo -->
        <div class="navs-left">
          <div class="logo">
            <img src="../../assets/images/tabs.png" alt="">
          </div>
        <!-- <div class="logo-title">EnjoyLink</div> -->
        </div>
        <div class="navs-right">
          <div class="navs-btn" :class="{'active-tag': activeNmae === '1'}" @click="clickTag('1')">
            <a href="#home">Home</a>
          </div>
          <div class="navs-btn" :class="{'active-tag': activeNmae === '2'}" @click="clickTag('2')">
            <a href="#server">产品服务</a>
          </div>
          <div class="navs-btn" :class="{'active-tag': activeNmae === '3'}" @click="clickTag('3')">
            <a href="#footerb">下载服务</a>
          </div>
          <el-button class="start-btn" @click="goStart">立即开始</el-button>
        </div>
      </div>
    </div>
  </template>

<script>
export default {
  created () {
    this.userName = window.sessionStorage.getItem('userName')
    this.$store.commit('setNumber', window.sessionStorage.getItem('number'))
  },
  data () {
    return {
      shopList: [],
      userName: '',
      activeNmae: '1'
    }
  },
  mounted () {
    const header = document.querySelector('.header')
    const that = this
    window.onscroll = function () {
      const scrollTop = document.documentElement.scrollTop
      if (scrollTop >= 200) {
        header.classList.add('headerFixed')
      } else {
        header.classList.remove('headerFixed')
      }
      if (scrollTop <= 800) {
        that.activeNmae = '1'
      } else if (scrollTop <= 2600) {
        that.activeNmae = '2'
      } else {
        that.activeNmae = '3'
      }
    }
  },
  methods: {
    goStart () {
      window.open('https://enjoylink.meteor.plus/', '_blank')
    },
    clickTag (index) {
      this.activeNmae = index
    },
    login () {
      this.$router.push('./Login')
    },
    async showShop () {

    },
    starList () {
      this.$router.push('/Star')
    }
  }
}
</script>

  <style lang="less" scoped>
  .header{
    background: #fff;
    width: 100%;
    padding: 0.375rem 0;
    transition: all 0.5s;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .headerFixed{
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffffffc2;
    transition: all 0.8s;
    box-shadow: 0 3px 6px rgba(105, 105, 105, 0.1);
  }
  .logo{
    img{
    //   height: 0.75rem;
    width: 5rem;
    height: auto;
    }
  }
  .logo-title {
    font-size: 0.62rem;
    line-height: 0.68rem;
    color: #1D1D1D;
    margin-left: 8px;
    font-weight: bold;
  }
  .navs-left {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .navs-right{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .navs-btn {
    a {
    color: #333333;
    }
    cursor: pointer;
    font-size: 0.5rem;
    line-height: 0.68rem;
    color: #333333;
    margin-right: 2.843rem;
    font-weight: bold;
  }
  .active-tag {
    color: #944FF7;
    a {
      color: #944FF7;
    }
  }
  .start-btn {
    background: linear-gradient(290deg, #944FF7 0%, #01F1B2 100%) !important;
    border: unset !important;
    color: #fff !important;
    border-radius: 10px !important;
  }
  @media screen and (max-width:992px){

  }
  @media screen and (max-width:768px){
    .flexAlign{
      justify-content: space-around;
    }
  }
  </style>
